<template>
    <el-dialog 
        :visible.sync="dialogVisible"
        width="500px"
        title="添加寻访记录"
        class="namelist-inquiry-dialog"
        v-loading="loading"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <div class="radio-container">
            <div>
                <label :class="validChecked?'radio-item selected':'radio-item'">
                    <span class="radio-wrap"><input type="radio" class="radio-input" name="remark" @click="handleValid"/></span>
                    <span>有效寻访</span>
                </label>
                <div class="common-inquiry" v-show="showValid">
                    <textarea class="remark-text" placeholder="添加一条新寻访记录" v-model="inquiryContent"></textarea>
                    <div class="inquiry-scroll">
                        <div class="inquiry-pannel">
                            <div class="inquiry-title">跳槽时机</div>
                            <ul class="inquiry-select-list">
                                <li :class="item.selected?'select-item selected':'select-item'"
                                v-for="(item, index) in opportunityArr" :key="index"
                                @click="handleItem(item,opportunityArr,'opportunitySelected')"
                                >{{item.text}}</li>
                            </ul>
                        </div>
                        <div class="inquiry-pannel">
                            <div class="inquiry-title">雇主画像</div>
                            <ul class="inquiry-select-list">
                                <li :class="item.selected?'select-item selected':'select-item'" v-for="(item, index) in employerArr" :key="index"
                                @click="handleItem(item,employerArr,'employerSelected')">{{item.text}}</li>
                            </ul>
                        </div>
                        <div class="inquiry-pannel">
                            <div class="inquiry-title">期望报酬</div>
                            <ul class="inquiry-select-list">
                                <li :class="item.selected?'select-item selected':'select-item'" v-for="(item, index) in rewardArr" :key="index"
                                @click="handleItem(item,rewardArr,'rewardSelected')">{{item.text}}</li>
                                <li :class="isCustom?'select-item selected':'select-item'" v-show="!isInput"
                                @click="handleItem({id:0,text:''},rewardArr,'rewardSelected')">{{customInput?customInput:'自定义'}}</li>
                                <input v-show="isInput" type="text" ref="customInput" class="custom-input"  maxLength="36" v-model="customInput"
                                @blur="handleBlur"/>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <label :class="invalidChecked?'radio-item selected':'radio-item'">
                    <span class="radio-wrap"><input type="radio" class="radio-input" name="remark" @click="handleInvalid"/></span>
                    <span>无效寻访</span>
                </label>
                <ul class="invalid-list" v-show="showInvalid">
                    <li :class="item.selected?'li-item selected':'li-item'" v-for="(item, index) in invalidItems" :key="index"
                    @click="handleInvalidItem(item)">
                        <span>{{item.text}}
                            <i class="remark" v-if="item.remark">({{item.remark}})</i>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="dialog-footer">
            <el-button @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    //import namelistInquiry from './namelist-inquiry';
    import { candidate as candidateUrl } from '#/js/config/api.json';

    //跳槽时机
    const opportunityList = [
        {
            id:1,
            text:'不考虑机会'
        },
        {
            id:2,
            text:'6个月内不考虑'
        },
        {
            id:3,
            text:'12个月内不考虑'
        },
        {
            id:4,
            text:'刚入职新公司'
        },
        {
            id:5,
            text:'看职业机会'
        },
        {
            id:6,
            text:'离职状态'
        },
        {
            id:7,
            text:'该公司最近有裁员传闻/新闻'
        }
    ]

    //雇主画像
    const employerList = [
        {
            id:1,
            text:'上市公司'
        },
        {
            id:2,
            text:'创业早期(天使/A/B/C轮)'
        },
        {
            id:3,
            text:'创业中后期(D/E/F轮)'
        },
        {
            id:4,
            text:'独角兽/行业巨头'
        },
        {
            id:5,
            text:'都可以'
        },
        {
            id:6,
            text:'外资企业/跨国企业'
        },{
            id:7,
            text:'知名成熟公司'
        }
    ]

    //期望报酬
    const rewardList = [
        {
            id:1,
            text:'20-30W'
        },
        {
            id:2,
            text:'30-50W'
        },
        {
            id:3,
            text:'50-80W'
        },
        {
            id:4,
            text:'80-100W'
        },
        {
            id:5,
            text:'100-150W'
        },
        {
            id:6,
            text:'150-200W'
        },
        {
            id:7,
            text:'200-300W'
        },
        {
            id:8,
            text:'300W以上'
        }
    ]
    const invalidList = [
        /* {
            id:1,
            text:'有效通话'
        }, */
        {
            id:2,
            text:'未接电话'
        },
        {
            id:3,
            text:'无效电话',
            remark:'空号/号码非本人'
        },
        {
            id:4,
            text:'电话打不通',
            remark:'关机/停机/无法接通'
        },
        {
            id:5,
            text:'暂不方便接听'
        },
        {
            id:6,
            text:'拒绝与猎头沟通'
        }
    ]


    export default {
        name: "namelistInquiry",
        components: {
        },
        props:{
            namelistId:{
                type:String,
                default:''
            },
            resumeId:{
                type:String,
                default:''
            },
            itemIndex:{
                type:Number,
                default:0
            }
        },
        data() {
            return {
                loading: false,
                dialogVisible: false,
                validChecked:false,
                opportunityArr:[],
                employerArr:[],
                rewardArr:[],
                opportunitySelected: '',
                employerSelected: '',
                rewardSelected: '',
                isInput: false,
                invalidItems: [],
                selectedId:null,
                validChecked: false,
                invalidChecked: false,
                inquiryContent: '',
                showInvalid: false,//控制无效寻访
                showValid:false,//用于控制有效寻访的显隐
                customInput:'',
                isCustom: false,

                invalidText:'',
            }
        },
        created() {
            opportunityList.map((item)=>{
                item.selected = false;
                this.opportunityArr.push(item);
            })
            employerList.map((item)=>{
                item.selected = false;
                this.employerArr.push(item);
            })
            rewardList.map((item)=>{
                item.selected = false;
                this.rewardArr.push(item);
            })
            invalidList.map((item)=>{
                item.selected = false;
                this.invalidItems.push(item)
            })
        },
        methods: {
            handleItem(option,itemArr,selectedKey){
                let selectedText = option.text;
                itemArr.map((item)=>{
                    if(item.text == selectedText){
                        item.selected = true;
                        this[selectedKey] = selectedText;
                    }else{
                        item.selected = false;
                    }
                })
                if(!option.id){
                    //自定义选项
                    this.isInput = true;
                    this.$nextTick(function(){
                        this.$refs.customInput.focus();
                    })

                }
                console.log(selectedKey,this[selectedKey],11111);
            },
            handleInvalidItem(item){
                let invalidItems = this.invalidItems;
                let id = item.id;
                invalidItems.map((item)=>{
                    if(item.id == id){
                        item.selected = true;
                        this.selectedId = id;
                        this.invalidChecked = true;
                        this.validChecked = false;
                        this.invalidText = item.text;
                    }else{
                        item.selected = false;
                    }
                })
                this.handleResetValid();

            },
            /* handleSubmit(){
                let dataJson = {
                    opportunitySelected:opportunitySelected,
                    employerSelected: employerSelected,
                    rewardSelected: rewardSelected
                }
                console.log(dataJson)
            }, */
            handleValid(){
                this.validChecked = true;
                this.invalidChecked = false;
                this.showInvalid = false;
                this.showValid = true;
                this.handleResetInvalid();
            },
            handleInvalid(){
                this.validChecked = false;
                this.invalidChecked = true;
                this.showInvalid = true;
                this.showValid = false;
            },
            handleBlur(e){
                let inputText = e.target.value;
                this.isInput = false;
                this.isCustom = true;
                this.selectedItem = {
                    id:0,
                    text: inputText
                }
            },
            handleResetInvalid(){
                let items = this.invalidItems;
                items.map((item)=>{
                    item.selected = false;
                })
                this.invalidText = '';
                this.invalidChecked = false;
                this.showInvalid = false;
            },
            handleResetValid(){
                this.opportunityArr.map((item)=>{
                        item.selected = false;
                })
                this.employerArr.map((item)=>{
                    item.selected = false;
                })
                this.rewardArr.map((item)=>{
                    item.selected = false;
                })
                this.isCustom = false;
                this.inquiryContent = '';
                this.validChecked = false;
                this.showValid = false;
                this.customInput = '';
                this.opportunitySelected = '';
                this.employerSelected = '';
                this.rewardSelected = '';

            },
            showModal(){
                this.dialogVisible = true;
            },
            handleConfirm(){
                //namelistInquiryJson为全局变量，由老页面赋值，新页面取值
                let addInquiryUrl = candidateUrl.namelist_add_inquiry;
                let content = this.inquiryContent;
                let dataJson = {
                    isValidInquiry:this.validChecked
                };
                //debugger
                if(this.validChecked){
                    if(!content){
                        shortTips('请填写寻访记录');
                        this.loading = false;
                        return
                    }
                    dataJson.content = content;
                    dataJson.jobHoppingOccasion = this.opportunitySelected;
                    dataJson.employerInfo = this.employerSelected;
                    if(this.isCustom){
                        dataJson.expectSalary = this.customInput;
                    }else{
                        dataJson.expectSalary = this.rewardSelected
                    }
                }else if(this.invalidChecked){
                    dataJson.content = this.invalidText;
                }else{
                    shortTips('请选择寻访选项');
                }

                _request({
                    url: addInquiryUrl.replace('%p', this.namelistId).replace('%p', this.resumeId),
                    method: 'POST',
                    data: dataJson
                }).then(res => {
                    this.handleResetValid();
                    this.handleResetInvalid();
                    let index = this.itemIndex;
                    try {
                        this.$emit('namelist-inquiry-add',index)
                    } catch (error) {
                        
                    }
                    this.dialogVisible = false;
                }).finally(() => {
                    this.loading = false;
                })
            },
            handleCancel(){
                this.handleResetValid();
                this.handleResetInvalid();
                this.dialogVisible = false;
            },
        },
    }
</script>

<style lang="scss">

.radio-container{
    font-size: 14px;
    color: #666;
    /* width: 608px;
    padding: 30px 20px 10px; */
    /* border: 1px solid #eee; */
    > p {
        line-height: 32px;
    }

    .radio-wrap{
        display: inline-block;
        position: relative;
        width: 16px;
        height: 16px;
        //background: url('@/icon/namelist-inquiry/radio_default.png') center no-repeat;
        border-radius: 50%;
        vertical-align: middle;
        & + span{
            margin-left: 2px;
        }
    }
    .radio-input{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
    }
    .radio-item{
        cursor: pointer;
        line-height: 32px;
        .radio-wrap{
            background: url('~@src/assets/images/namelist-inquiry/icon_circle.png') center no-repeat;
        }

        /* &:hover {
            .radio-wrap{
                background: url('@/icon/namelist-inquiry/icon_circle_active.png') center no-repeat;

                border: none;
            }
        } */

        &.selected{
            color:#37BC9C;
            .radio-wrap{
                background: url('~@src/assets/images/namelist-inquiry/icon_circle_active.png') center no-repeat;
                border: none;
                background-size: cover;
            }
            .remark{
                color: #37BC9C;
            }
        }
        >span{
            vertical-align: middle;
        }
        .remark{
            color: #999;
        }
    }
    .check-item{
        .check-wrap{
            display: inline-block;
            position: relative;
            width: 12px;
            height: 12px;
            border: 1px solid #999;
            margin-right: 6px;
            //background: url('@/icon/namelist-inquiry/radio_default.png') center no-repeat;

        }
        &.selected{
            .check-wrap{
                background: url('~@src/assets/images/namelist-inquiry/radio_checked.png') center no-repeat;
                border: none;
            }
        }
        >span{
            vertical-align: middle;
        }
    }
    .remark-text{
        display: block;
        background: #FFFFFF;
        border: 1px solid #ddd;
        border-radius: 2px;
        width:100%;
        height: 72px;
        overflow-y: auto;
        resize: none;
        padding: 5px 10px;
        font-size: 14px;
        margin-bottom: 20px;
        &::placeholder{
            color: #aaa;
        }

        &:focus {
            border-color: #7BCCBB;
        }

        &.has-content {
            border: 1px solid #ddd;
            color: #666;
            background-color: #E5F5F1;
        }
    }
}

.common-inquiry{
    width: 100%;
    line-height: initial;
    margin-bottom: 10px;
    .inquiry-scroll{
        height: 320px;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .inquiry-pannel{
       margin-top: 12px;
       text-align: left;
       .inquiry-title{
            font-size: 14px;
            margin: 10px 0;
            color: #444;
        }
        &:first-child{
            margin-top: 0;
        }

        .inquiry-input-wrap{
            width: 100%;
            background: #fff;
            height: 28px;
            line-height: 28px;
            margin-bottom: 10px;
            font-size: 14px;
            overflow: hidden;
            &:last-child{
                margin-bottom: 0;
            }
            >input{
                border: 1px solid #E6EAEE;
                border-radius: 2px;
                width: 100%;
                height: 100%;
                padding: 0 10px;
            }
        }
    }
}
.invalid-list{
    padding: 0 20px;
    .li-item{
        height: 40px;
        line-height: 40px;
        padding: 0 14px;
        cursor: pointer;
        &:hover{
            color: #38BC9D;
        }
        &.selected{
            background:rgba(56,188,157,.2);
            color: #38BC9D;
        }
    }
}


.inquiry-select-list{
    display: flex;
    color: rgba(53,64,82,.5);
    flex-wrap: wrap;
    margin-bottom: 0;
    .select-item{
        display: inline-block;
        padding: 4px 10px;
        border: 1px solid #ddd;
        border-radius: 2px;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        &:hover,&.selected{
            color: #38BC9D;
            border: 1px solid #38BC9D;
        }

    }
    .custom-input{
        width: 60px;
        height: 29px;
        line-height: 29px;
        border: 1px solid #E6EAEE;
        border-radius: 4px;
        padding: 0 8px;
    }
    &.call-box{
        //针对电话助手的一套样式
        font-size: 14px;
        color: #999;
        .select-item{
            display: inline-block;
            padding: 3px 9px;
            border: 1px solid #ddd;
            border-radius: 4px;
            margin-right: 8px;
            margin-bottom: 8px;
            cursor: pointer;
            &:hover,&.selected{
                color: #65B99D;
                border: 1px solid #65B99D;
            }

        }
        .custom-input{
            width: 62px;
            height: 28px;
            line-height: 28px;
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 0 8px;
        }
    }
}

</style>
