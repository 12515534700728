var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "namelist-inquiry-dialog",
      attrs: {
        visible: _vm.dialogVisible,
        width: "500px",
        title: "添加寻访记录",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "radio-container" }, [
        _c("div", [
          _c(
            "label",
            { class: _vm.validChecked ? "radio-item selected" : "radio-item" },
            [
              _c("span", { staticClass: "radio-wrap" }, [
                _c("input", {
                  staticClass: "radio-input",
                  attrs: { type: "radio", name: "remark" },
                  on: { click: _vm.handleValid },
                }),
              ]),
              _c("span", [_vm._v("有效寻访")]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showValid,
                  expression: "showValid",
                },
              ],
              staticClass: "common-inquiry",
            },
            [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inquiryContent,
                    expression: "inquiryContent",
                  },
                ],
                staticClass: "remark-text",
                attrs: { placeholder: "添加一条新寻访记录" },
                domProps: { value: _vm.inquiryContent },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.inquiryContent = $event.target.value
                  },
                },
              }),
              _c("div", { staticClass: "inquiry-scroll" }, [
                _c("div", { staticClass: "inquiry-pannel" }, [
                  _c("div", { staticClass: "inquiry-title" }, [
                    _vm._v("跳槽时机"),
                  ]),
                  _c(
                    "ul",
                    { staticClass: "inquiry-select-list" },
                    _vm._l(_vm.opportunityArr, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          class: item.selected
                            ? "select-item selected"
                            : "select-item",
                          on: {
                            click: function ($event) {
                              return _vm.handleItem(
                                item,
                                _vm.opportunityArr,
                                "opportunitySelected"
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.text))]
                      )
                    }),
                    0
                  ),
                ]),
                _c("div", { staticClass: "inquiry-pannel" }, [
                  _c("div", { staticClass: "inquiry-title" }, [
                    _vm._v("雇主画像"),
                  ]),
                  _c(
                    "ul",
                    { staticClass: "inquiry-select-list" },
                    _vm._l(_vm.employerArr, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          class: item.selected
                            ? "select-item selected"
                            : "select-item",
                          on: {
                            click: function ($event) {
                              return _vm.handleItem(
                                item,
                                _vm.employerArr,
                                "employerSelected"
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.text))]
                      )
                    }),
                    0
                  ),
                ]),
                _c("div", { staticClass: "inquiry-pannel" }, [
                  _c("div", { staticClass: "inquiry-title" }, [
                    _vm._v("期望报酬"),
                  ]),
                  _c(
                    "ul",
                    { staticClass: "inquiry-select-list" },
                    [
                      _vm._l(_vm.rewardArr, function (item, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            class: item.selected
                              ? "select-item selected"
                              : "select-item",
                            on: {
                              click: function ($event) {
                                return _vm.handleItem(
                                  item,
                                  _vm.rewardArr,
                                  "rewardSelected"
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.text))]
                        )
                      }),
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isInput,
                              expression: "!isInput",
                            },
                          ],
                          class: _vm.isCustom
                            ? "select-item selected"
                            : "select-item",
                          on: {
                            click: function ($event) {
                              return _vm.handleItem(
                                { id: 0, text: "" },
                                _vm.rewardArr,
                                "rewardSelected"
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.customInput ? _vm.customInput : "自定义")
                          ),
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isInput,
                            expression: "isInput",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customInput,
                            expression: "customInput",
                          },
                        ],
                        ref: "customInput",
                        staticClass: "custom-input",
                        attrs: { type: "text", maxLength: "36" },
                        domProps: { value: _vm.customInput },
                        on: {
                          blur: _vm.handleBlur,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.customInput = $event.target.value
                          },
                        },
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]
          ),
        ]),
        _c("div", [
          _c(
            "label",
            {
              class: _vm.invalidChecked ? "radio-item selected" : "radio-item",
            },
            [
              _c("span", { staticClass: "radio-wrap" }, [
                _c("input", {
                  staticClass: "radio-input",
                  attrs: { type: "radio", name: "remark" },
                  on: { click: _vm.handleInvalid },
                }),
              ]),
              _c("span", [_vm._v("无效寻访")]),
            ]
          ),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showInvalid,
                  expression: "showInvalid",
                },
              ],
              staticClass: "invalid-list",
            },
            _vm._l(_vm.invalidItems, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: item.selected ? "li-item selected" : "li-item",
                  on: {
                    click: function ($event) {
                      return _vm.handleInvalidItem(item)
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(item.text) + "\n                        "),
                    item.remark
                      ? _c("i", { staticClass: "remark" }, [
                          _vm._v("(" + _vm._s(item.remark) + ")"),
                        ])
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }