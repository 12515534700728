var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "namelist-candidate-view visible" },
    [
      _c("namelist-search-area", {
        ref: "namelistSearchArea",
        attrs: { "page-view-id": _vm.pageViewId },
        on: { "action-dosearch": _vm.handleDoSearch },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "candidate-list",
        },
        [
          _vm.candidateList.length == 0 && !_vm.loading
            ? _c("div", { staticClass: "candidate-list-empty" }, [
                _c("span", { staticClass: "empty-img" }),
                _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
              ])
            : _vm._e(),
          _vm.candidateList.length > 0
            ? _c("candidate-list", {
                ref: "candidateListArea",
                attrs: {
                  candidateList: _vm.candidateList,
                  currentPage: _vm.pager.current,
                },
                on: {
                  "close-candidate-loading": _vm.closeCandidateLoading,
                  "open-candidate-loading": _vm.openCandidateLoading,
                  "close-candidate-inquiry-loading":
                    _vm.closeCandidateInquiryLoading,
                  "open-candidate-inquiry-loading":
                    _vm.openCandidateInquiryLoading,
                },
              })
            : _vm._e(),
          _vm.candidateList.length > 0
            ? _c(
                "el-pagination",
                {
                  staticClass: "candidate-list-pagination",
                  attrs: {
                    "current-page": _vm.pager.current,
                    "page-sizes": [20, 30, 50],
                    "page-size": _vm.pager.size,
                    layout: "prev, pager, next, slot, total, sizes",
                    total: _vm.pager.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                },
                [
                  _c("span", { staticClass: "pagination-text" }, [
                    _c(
                      "span",
                      [
                        _vm._v(
                          "\n                        前往\n                        "
                        ),
                        _c("el-input", {
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handlePagerJump(_vm.pagerJump)
                            },
                          },
                          model: {
                            value: _vm.pagerJump,
                            callback: function ($$v) {
                              _vm.pagerJump = _vm._n($$v)
                            },
                            expression: "pagerJump",
                          },
                        }),
                        _vm._v(
                          "\n                        页\n                    "
                        ),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.handlePagerJump(_vm.pagerJump)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        跳转\n                    "
                        ),
                      ]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.departments.length > 0,
              expression: "departments.length >0",
            },
          ],
          staticClass: "col-6 offset-1 col-right-board col-right-board-filter",
        },
        [
          _c("namelist-filter", {
            ref: "namelistFilter",
            attrs: { departments: _vm.departments },
            on: { "search-department": _vm.handleDepartmentSearch },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }