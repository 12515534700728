<template>
    <div class="name-list-filter">
        <!-- <div class="name-list-filter-item hot-company">
            <div class="filter-item-title">
                热门公司
            </div>
            <div class="filter-item-list">
                <div v-for="company in hotCompany" class="item-list-metadata" :class="{'company-active':company.companyStandardName === searchParams.companyName}" @click="handleCompanySearch(company)">
                    <span>{{company.companyStandardName + '(' + company.count + ')'}}</span>
                </div>
            </div>
        </div> -->
        <div v-if="departments.length > 0" class="name-list-filter-item department">
            <div class="filter-item-title"  :title="searchParams.companyName + '部门'">{{searchParams.companyName + '部门'}}</div>
            <div class="filter-item-list">
                <span v-for="item in departments">
                    <div v-if="item.department !== '其他'" class="department-list" :class="{'department-active':item.department === searchParams.department}" @click=" handleDepartmentSearch(item)">
                        <span class="department-item department-name" v-text="item.department" :title="item.department"></span>
                        <!-- v-text="item.count" -->
                        <span class="department-item department-count"></span>
                    </div>
                </span>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props:{
        // hotCompany:{
        //     type:Array,
        //     default:[]
        // },
        departments:{
            type:Array,
            default:[]
        }
    },
    data(){
        return{
            searchParams:[]
        }
    },
    methods: {

        // handleCompanySearch(company) {
        //     if(company.companyStandardName === this.searchParams.companyName) {
        //         this.$emit("search-company",{type:'delete'})
        //     }else {
        //         this.$emit("search-company",{type:'add',companyName:company.companyStandardName})
        //     }
        // },

        handleDepartmentSearch(item) {
            if(item.department === this.searchParams.department) {
                this.$emit("search-department",{type:'delete'})
            }else {
                this.$emit("search-department",{type:'add',departmentName:item.department})                
            }
        }
    },
}

</script>

<style lang="scss" scoped>
%ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.name-list-filter {
    padding: 0;

    &-item {
        margin-bottom: 20px;

        .filter-item-title {
            font-size: 16px;
            color: #666;
            font-weight: bolder;
            position: relative;
            text-indent: 28px;
            @extend %ellipsis;

            &::before {
                content: " ";
                position: absolute;
                left: 10px;
                top: 0;
                bottom: 0;
                width: 8px;
                background: #38bc9d;
                height: 85%;
                margin: auto;
            }
        }

        .filter-item-list {
            margin-top: 10px;
            border: 1px solid #ddd;
            border-radius: 1px;
            padding: 24px 20px;
            line-height: 2;

            .item-list-metadata {
                display: inline-block;
                vertical-align: top;
                cursor: pointer;
                padding: 0 5px;
                margin: 0 10px 3px 0;
            }
        }
    }

    .department {

        .filter-item-list {
            max-height: 850px;
            overflow-y: auto;
            padding: 20px 0;

            .department-list {
                cursor: pointer;
                line-height: 3;
                padding: 0 20px;

                &:hover {
                    background: #eee;
                }

                .department-item {
                    display: inline-block;
                    vertical-align: top;
                    @extend %ellipsis;
                }

                .department-name {
                    width: 195px;
                }

                .department-count {
                    width: 65px;
                    float: right;
                    text-align: right;
                    font-weight: bold;
                }
            }
        }

        ::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: #eee;
        }
        ::-webkit-scrollbar-track {
            border-radius: 4px;
            background: transparent;
        }
    }

    .company-active {
        color: #38bc9c;
        background-color: #e7f7f3;
        border-radius: 25px;
    }

    .department-active {
        color: #34b092;
        font-weight: bold;
    }
}
</style>
