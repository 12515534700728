var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "namelist-search-vue" },
    [
      _c(
        "div",
        { staticClass: "search-area" },
        [
          _c(
            "el-form",
            {
              ref: "candidateSearchForm",
              staticClass: "namelist-search-form",
              attrs: {
                size: "mini",
                model: _vm.candidateSearch,
                rules: _vm.candidateSearchRules,
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "location-item", attrs: { prop: "location" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "search-city-btn",
                      attrs: { title: _vm.selectedCity.text },
                    },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom-start",
                            width: "629",
                            "visible-arrow": _vm.provinceCityConfig.arrowIsShow,
                            trigger: "click",
                          },
                          model: {
                            value: _vm.provinceCityConfig.provinceCityIsShow,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.provinceCityConfig,
                                "provinceCityIsShow",
                                $$v
                              )
                            },
                            expression: "provinceCityConfig.provinceCityIsShow",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "search-city-content",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _c("i", [_vm._v(_vm._s(_vm.selectedCity.text))]),
                              _c("span", { staticClass: "el-icon-arrow-down" }),
                            ]
                          ),
                          _c("province-city", {
                            ref: "provinceCity",
                            attrs: {
                              "grid-length": 7,
                              enableHeader: _vm.provinceCityConfig.enableHeader,
                              enableFooter: _vm.provinceCityConfig.enableFooter,
                            },
                            on: {
                              confirm: _vm.handleCitySelectConfirm,
                              cancel: _vm.handleCitySelectCancel,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-input", {
                    staticClass: "search-input search-input-key",
                    attrs: {
                      maxlength: 128,
                      placeholder: "多个关键词使用空格隔开",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.candidateSearch.keywords,
                      callback: function ($$v) {
                        _vm.$set(_vm.candidateSearch, "keywords", $$v)
                      },
                      expression: "candidateSearch.keywords",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "title" } },
                [
                  _c("span", { staticClass: "form-item-label" }, [
                    _vm._v("职位"),
                  ]),
                  _c("el-autocomplete", {
                    staticClass: "search-input",
                    attrs: {
                      "popper-class": "namelist-el-autocomplete",
                      "fetch-suggestions": _vm.queryTitleSearch,
                    },
                    on: { select: _vm.handleTitleSelect },
                    nativeOn: {
                      keyup: function ($event) {
                        return _vm.handleTitleInput.apply(null, arguments)
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "name",
                                class: {
                                  selected:
                                    item.titleStandardName == _vm.oldTitle,
                                },
                              },
                              [
                                _vm._v(_vm._s(item.titleStandardName) + "("),
                                _c("i", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(item.count, "", 0)
                                    )
                                  ),
                                ]),
                                _vm._v(")"),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.candidateSearch.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.candidateSearch, "title", $$v)
                      },
                      expression: "candidateSearch.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "companyName" } },
                [
                  _c("span", { staticClass: "form-item-label" }, [
                    _vm._v("公司"),
                  ]),
                  _c("el-autocomplete", {
                    staticClass: "search-input",
                    attrs: {
                      "popper-class": "namelist-el-autocomplete",
                      "fetch-suggestions": _vm.queryCompanySearch,
                    },
                    on: { select: _vm.handleCompanySelect },
                    nativeOn: {
                      keyup: function ($event) {
                        return _vm.handleCompanyInput.apply(null, arguments)
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "name",
                                class: {
                                  selected:
                                    item.companyStandardName ==
                                    _vm.oldCompanyName,
                                },
                              },
                              [
                                _vm._v(_vm._s(item.companyStandardName) + "("),
                                _c("i", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(item.count, "", 0)
                                    )
                                  ),
                                ]),
                                _vm._v(")"),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.candidateSearch.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.candidateSearch, "companyName", $$v)
                      },
                      expression: "candidateSearch.companyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "realName" } },
                [
                  _c("span", { staticClass: "form-item-label" }, [
                    _vm._v("姓名"),
                  ]),
                  _c("el-input", {
                    staticClass: "search-input",
                    attrs: { maxlength: 128 },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.candidateSearch.realName,
                      callback: function ($$v) {
                        _vm.$set(_vm.candidateSearch, "realName", $$v)
                      },
                      expression: "candidateSearch.realName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "inquiryLog" } },
                [
                  _c("span", { staticClass: "form-item-label" }, [
                    _vm._v("寻访记录"),
                  ]),
                  _c("el-input", {
                    staticClass: "search-input",
                    attrs: { maxlength: 128 },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.candidateSearch.inquiryLog,
                      callback: function ($$v) {
                        _vm.$set(_vm.candidateSearch, "inquiryLog", $$v)
                      },
                      expression: "candidateSearch.inquiryLog",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("search-summary", {
        ref: "searchSummary",
        on: {
          "search-summary-remove": _vm.handleRemoteSummary,
          "clear-search": _vm.clearSearch,
        },
      }),
      _c("div", { staticClass: "more-params clearfix" }, [
        _c("div", { staticClass: "filter-list-received" }, [
          _c(
            "label",
            {
              staticClass: "filter-radio",
              class: { active: _vm.candidateSearch.isListReceived },
              on: { click: _vm.onFilterRadioClick },
            },
            [
              _vm._m(0),
              _c("span", { staticClass: "el-radio__label" }, [
                _vm._v("已领取"),
              ]),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "el-radio__input" }, [
      _c("span", { staticClass: "el-radio__inner" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }