var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "name-list-filter" }, [
    _vm.departments.length > 0
      ? _c("div", { staticClass: "name-list-filter-item department" }, [
          _c(
            "div",
            {
              staticClass: "filter-item-title",
              attrs: { title: _vm.searchParams.companyName + "部门" },
            },
            [_vm._v(_vm._s(_vm.searchParams.companyName + "部门"))]
          ),
          _c(
            "div",
            { staticClass: "filter-item-list" },
            _vm._l(_vm.departments, function (item) {
              return _c("span", [
                item.department !== "其他"
                  ? _c(
                      "div",
                      {
                        staticClass: "department-list",
                        class: {
                          "department-active":
                            item.department === _vm.searchParams.department,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDepartmentSearch(item)
                          },
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "department-item department-name",
                          attrs: { title: item.department },
                          domProps: { textContent: _vm._s(item.department) },
                        }),
                        _c("span", {
                          staticClass: "department-item department-count",
                        }),
                      ]
                    )
                  : _vm._e(),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }