<template>
    <div class="namelist-search-vue">
        <div class="search-area">
            <el-form
                size="mini"
                class="namelist-search-form"
                :model="candidateSearch"
                ref="candidateSearchForm"
                :rules="candidateSearchRules"
            >
                <el-form-item prop="location" class="location-item">
                    <div class="search-city-btn" :title="selectedCity.text">
                        <el-popover
                            placement="bottom-start"
                            width="629"
                            :visible-arrow="provinceCityConfig.arrowIsShow"
                            v-model="provinceCityConfig.provinceCityIsShow"
                            trigger="click"
                        >
                            <span slot="reference" class="search-city-content">
                                <i>{{ selectedCity.text }}</i>
                                <span class="el-icon-arrow-down"></span>
                            </span>

                            <province-city ref="provinceCity" :grid-length="7"
                                           :enableHeader="provinceCityConfig.enableHeader"
                                           :enableFooter="provinceCityConfig.enableFooter"
                                           @confirm="handleCitySelectConfirm"
                                           @cancel="handleCitySelectCancel"></province-city>
                        </el-popover>
                    </div>
                    <el-input
                        class="search-input search-input-key"
                        :maxlength="128"
                        v-model="candidateSearch.keywords"
                        placeholder="多个关键词使用空格隔开"
                        @keyup.enter.native="search"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="title">
                    <span class="form-item-label">职位</span>
                    <el-autocomplete v-model="candidateSearch.title" class="search-input" popper-class="namelist-el-autocomplete" :fetch-suggestions="queryTitleSearch" @keyup.native="handleTitleInput" @select="handleTitleSelect">
                        <template slot-scope="{ item }">
                            <div class="name" :class="{'selected':item.titleStandardName==oldTitle}">{{item.titleStandardName}}(<i>{{item.count| currency('',0)}}</i>)</div>
                        </template>
                    </el-autocomplete>
                </el-form-item>
                <el-form-item prop="companyName">
                    <span class="form-item-label">公司</span>
                    <el-autocomplete v-model="candidateSearch.companyName" class="search-input" popper-class="namelist-el-autocomplete" :fetch-suggestions="queryCompanySearch" @keyup.native="handleCompanyInput" @select="handleCompanySelect">
                        <template slot-scope="{ item }">
                            <div class="name" :class="{'selected':item.companyStandardName==oldCompanyName}">{{item.companyStandardName}}(<i>{{item.count| currency('',0)}}</i>)</div>
                        </template>
                    </el-autocomplete>
                </el-form-item>
                <!-- <p class="guess-line">猜你想搜：</p> -->
                <el-form-item prop="realName">
                    <span class="form-item-label">姓名</span>
                    <el-input
                        class="search-input"
                        :maxlength="128"
                        v-model="candidateSearch.realName"
                        @keyup.enter.native="search"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="inquiryLog">
                    <span class="form-item-label">寻访记录</span>
                    <el-input
                        class="search-input"
                        :maxlength="128"
                        v-model="candidateSearch.inquiryLog"
                        @keyup.enter.native="search"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <search-summary ref="searchSummary" @search-summary-remove="handleRemoteSummary" @clear-search="clearSearch"></search-summary>

        <div class="more-params clearfix">
            <div class="filter-list-received">
                <label class="filter-radio" :class="{'active':candidateSearch.isListReceived}" @click="onFilterRadioClick">
                    <span class="el-radio__input">
                        <span class="el-radio__inner"></span>
                    </span>
                    <span class="el-radio__label">已领取</span>
                </label>
            </div>
        </div>

        <!-- <modal class="area-modal" ref="areaModal" caption="选择城市信息" confirm-btn-text="确定" cancel-btn-text="取消" :enable-modal="true" :enable-close="true" :enable-confirm-close="true" :enable-queue="true" @on-confirm="handleCitySelect">
            <div class="detail">
                <province-city ref="area"></province-city>
            </div>
        </modal> -->
    </div>
</template>
<script>
import candidateSearchMap from '#/js/config/candidateSearchMap.json';
import { internationalMobile as mobileTest, email as emailTest, telephone as telephoneTest } from '#/js/util/validators.js';

import ProvinceCity from '#/component/common/province-city.vue';
import searchSummary from '../personal-candidates/search-summary.vue';

const DEFAULT_DATERANGE_FORMAT = "YYYY-MM-DD";

function formatData(items) {
    items.forEach(item => {
        item.text = item.text || item.Text || item.name;
        item.value = item.value || item.Value;
    })
    return items;
}

const DEFAULT_AREA = '全国';
const DEFAULT_AREA_ITEM = {
    id: 0,
    text: DEFAULT_AREA
};

const SUMMARY_PRRAM = {
    companyName: '',
    title: '',
    keywords: '',
    location: '',
    realName: '',
    inquiryLog: ''
}

export default {
    components:{
        ProvinceCity,
        searchSummary,
    },
    props:{
        pageViewId: String,
        isAllSelected: Boolean,
        importLoading: Boolean
    },
    data(){
        return{
            provinceCityConfig: {
                enableHeader: true,
                enableFooter: true,
                provinceCityIsShow: false,
                arrowIsShow: false,
            },
            selectedCity: {
                text: '全国',
                id: '',
                isSelect: true,
            },
            companyGroupedItems: [],
            titleGroupedItems: [],
            params:{},
            oldCompanyName: '',
            oldTitle: '',
            candidateSearch: {
                companyName: '',
                department: '',
                title: '',
                keywords: '',
                location: '',
                realName: '',
                inquiryLog: '',
                isListReceived: false,
            },
            candidateSearchRules: {
            },
        }
    },
    computed:{
    },
    mounted(){
        if (localStorage.getItem('myCompanyCandidatesParams')) {
            Object.assign(this.params, JSON.parse(localStorage.getItem('myCompanyCandidatesParams')));

            localStorage.removeItem('myCompanyCandidatesParams');
        }
        

        this.areaModal = this.$refs.areaModal;
        this.area = this.$refs.area;
        this.summary = this.$refs.searchSummary;
    },
    methods: {
        clearSearch() {
            this._clearAllFilters();

            this.search();
        },
        
        getSearchParamName(key,value){
            return candidateSearchMap[key] || '';
        },

        queryTitleSearch(queryString, cb) {
            let titleGroupedItems = this.titleGroupedItems;
            let results = queryString ? titleGroupedItems.filter(this.createFilter(queryString,'title')) : titleGroupedItems;
            cb(results);
        },
        queryCompanySearch(queryString, cb) {
            let companyGroupedItems = this.companyGroupedItems;
            let results = queryString ? companyGroupedItems.filter(this.createFilter(queryString,'company')) : companyGroupedItems;
            cb(results);
        },
        createFilter(queryString,type) {
            if(type =='title'){
                return (titleGroupedItem) => {
                    return (titleGroupedItem.titleStandardName.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
                };
            }else{
                return (companyGroupedItem) => {
                    return (companyGroupedItem.companyStandardName.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
                };
            }
        },

        handleTitleSelect(val){
            if(this.oldTitle == val.titleStandardName){
                this.oldTitle = '';
                this.candidateSearch.title = '';
                this.search();
                return
            }
            this.candidateSearch.title = val.titleStandardName;
            this.oldTitle = val.titleStandardName;
            this.search();
        },
        handleTitleInput(e){
            if(e.keyCode ==13){
                this.search();
                return
            }
            if(this.candidateSearch.title != this.oldTitle){
                this.oldTitle = '';
            }
        },
        handleCompanyInput(e){
            if(e.keyCode ==13){
                this.search();
                return
            }
            if(this.candidateSearch.companyName != this.oldCompanyName){
                this.oldCompanyName = '';
            }
        },
        handleCompanySelect(val){
            if(this.oldCompanyName == val.companyStandardName){
                this.oldCompanyName = '';
                this.candidateSearch.companyName = '';
                this.candidateSearch.department='';
                this.search();
                return
            }
            this.oldCompanyName = val.companyStandardName;
            this.candidateSearch.companyName = val.companyStandardName;
            this.search();
        },

        reloadFieldsData(data) {
            this.titleGroupedItems = data.titleData;
            this.companyGroupedItems = data.companyData;
        },

        makeUpSummary(param) {
            let _self = this,arr = [],description='';
            Object.keys(param).forEach(key =>{
                let value = param[key];
                if(key in SUMMARY_PRRAM && value) {
                    switch (key) {
                        case 'location':
                            value = this.selectedCity.text;
                            break;
                        case 'keywords':
                        case 'title':
                        case 'companyName':
                        case 'realName':
                        case 'inquiryLog':
                            value = value;
                            break;
                    }

                    description = _self.getSearchParamName(key,value);

                    if(value){
                        arr.push({
                            id: key,
                            text: value,
                            description:description
                        });
                    }
                }
            })
            this.summary.items=arr;
        },

        extractParams() {
            this.params.title = this.candidateSearch.title;
            this.params.companyName = this.candidateSearch.companyName;
            this.params.inquiryLog = this.candidateSearch.inquiryLog;
            this.params.realName = this.candidateSearch.realName;
            this.params.keywords = this.candidateSearch.keywords;
            this.params.isReceived = this.candidateSearch.isListReceived;
            this.params.department = this.candidateSearch.department;
        },

        validateParam() {
            let valid = true;
            Object.keys(this.params).forEach(key => {
                let value = this.params[key];
                if(value && value.trim) {
                    value = value.trim();
                }
                if(_isDangerousString(value)){
                    valid = false;
                    shortTips('您输入的内容包含非法字符，请重新输入');
                    return false;
                }
            })
            return valid;
        },
        search() {
            this.extractParams();
            if(this.validateParam()){
                this.$emit('action-dosearch', this.params);
                this.makeUpSummary(this.params);
            }
        },

        onRemoteSummaryItem(item) {
            switch (item.id) {
                case "location":
                    this._removeLocation();
                    break;
                case "companyName":
                    this._removeCompanyName();
                    break;
                case "title":
                    this._removeTitle();
                    break;
                case 'isListReceived':
                    this._removeIsListReceived();
                    break;
                case "keywords":
                    this._removeKeywords();
                    break;
                case "realName":
                    this._removeRealName();
                    break;
                case "department":
                    this._removeDepartment();
                    break;
                case "inquiryLog":
                    this._removeInquiryLog();
                    break;
                default:
                    break;
            }

            this.search();
        },

        _clearAllFilters(){
            this._removeLocation();
            this._removeCompanyName();
            this._removeTitle();
            this._removeKeywords();
            this._removeRealName();
            this._removeInquiryLog();
            this._removeDepartment();
            this._removeIsListReceived();
        },

        _removeCompanyName() {
            this.candidateSearch.companyName = '';
            this.candidateSearch.department = '';
            this.oldCompanyName = '';
        },

        _removeDepartment() {
            this.candidateSearch.department = '';
        },

        _removeTitle() {
            this.candidateSearch.title = '';
            this.oldTitle = '';
        },

        _removeKeywords() {
            this.params.keywords = '';
            this.candidateSearch.keywords='';
        },

        _removeLocation() {
            this.$refs.provinceCity.clearSelected(true);
            this.selectedCity = {
                text: '全国',
                id: '',
                isSelect: true,
            };
            this.params.locationText = '';
            this.params.location  = '';
            this.candidateSearch.location = '';
        },

        _removeRealName() {
            this.candidateSearch.realName='';
        },

        _removeInquiryLog() {
            this.candidateSearch.inquiryLog='';
        },

        _removeIsListReceived() {
            this.candidateSearch.isListReceived = false;
        },

        _onBatchImportClick(){
            this.$emit('event-batchImport-click')
        },
        
        handleCitySelectConfirm() {
            if (this.$refs.provinceCity.selectedCity[0]) {
                this.selectedCity = this.$refs.provinceCity.selectedCity[0];
                this.params.location  = this.selectedCity.id;
                this.params.locationText = this.selectedCity.text;
            } else {
                this.selectedCity = {
                    text: '全国',
                    id: '',
                    isSelect: true,
                };
                this.params.locationText = '';
                this.params.location  = '';
            }
            this.search();

            this.$refs.provinceCity.currentIndex = undefined;
            this.$refs.provinceCity.selectedProvince = '';
            return this.provinceCityConfig.provinceCityIsShow = false;
        },
        handleCitySelectCancel() {
            if (this.$refs.provinceCity.selectedCity[0] && this.$refs.provinceCity.selectedCity[0].id !== this.selectedCity.id) {
                this.$refs.provinceCity.selectCity(this.selectedCity);
            }
            this.$refs.provinceCity.currentIndex = undefined;
            this.$refs.provinceCity.selectedProvince = '';
            return this.provinceCityConfig.provinceCityIsShow = false;
        },
        
        handleRemoteSummary(item){
            this.onRemoteSummaryItem(item);
        },
        handleCheckALL(val){
            console.log(val);
            this.$emit('check-all',val);
        },
        onFilterRadioClick(){
            this.candidateSearch.isListReceived = !this.candidateSearch.isListReceived;
            this.search();
        },
    },
};

</script>

<style lang="scss">
.search-area{
    display: block;
    position: relative;
    // padding: 24px 12px 0;
    padding: 20px;
    // background-color: #f8f8f8;
    background-color: #ffffff;
    border-radius: 0 0 8px 8px;
}

.search-wrap .search-icon .icon-font{
    font-size: 20px;
}

.search-wrap {
    padding: 10px 0;
}

.more-params{
    width: calc(100% - 420px);
    .check-all-box{
        margin-left: 12px;
        position: absolute;
        top: 14px;
        .trigger-label{
            color: #bbb;
            font-size: 14px;
        }
    }
    .import-candidate-btn{
        background: #38bc9c;
        border-radius: 2px;
        height: 36px;
        width: 130px;
        float: right;
        text-align: center;
        color: #fff;
        line-height: 36px;
        user-select: none;
        &:hover{
            cursor: pointer;
        }
    }

    .import-candidate-gray {
        float: right;
        margin-right: 20px;

        .btn-text {
            display: inline-block;
            width: 196px;
            height: 36px;
            line-height: 36px;
            background: #FDAA65;
            border-radius: 2px;
            cursor: pointer;
            text-align: center;
            color: #fff;
            margin-left: 5px;

            &:hover {
                background-color: #E38736;;
            }
            
            &-gray {
                background-color: #D3D3D3;

                &:hover {
                    background-color: #D3D3D3;
                    cursor: not-allowed;
                }
            }
        }

        .icon-font {
            &:hover {
                color: #FDAA65;
            }
        }
    }
}

.more-params .dropmenu .dropmenu-text {
    color: #666;
}
.param-item-tip .icon-font {
    transform: scale(0.5);
    color: $primary;
}

.param-item-tip>span {
    cursor: pointer;
    margin-top: -10px;
}

.namelist-search-vue{

    // .namelist-search-form {
    //     position: relative;
    //     display: flex;
    //     flex-wrap: wrap;
    //     justify-content: space-between;

    //     .form-select-item.el-form-item {
    //         .search-input.el-date-editor {
    //             .el-input__icon {
    //                 display: none;
    //             }
    //         }
    //         .el-input__inner {
    //             width: 397px;
    //         }
    //     }

    //     .guess-line {
    //         display: block;
    //         width: 100%;
    //     }

    //     .search-city-content {
    //         display: flex;
    //         align-items: center;

    //         i {
    //             display: inline-block;
    //             max-width: 50px;
    //             overflow: hidden;
    //             text-overflow: ellipsis;
    //             white-space: nowrap;
    //         }
    //     }
    // }

    .namelist-search-form {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 50px;

        .guess-line {
            display: block;
            width: 100%;
        }

        .search-city-btn > span {
            display: inline-block;
        }

        .search-city-content {
            display: flex;
            align-items: center;
            padding-right: 16px;

            i {
                display: inline-block;
                padding: 0 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .el-form-item--mini.el-form-item {
            width: 23%;
            display: inline-block;
            margin-bottom: 16px;
            &:first-child {
                width: 100%;
            }
            .el-form-item__content .el-input__inner,
            .el-form-item__label {
                height: 36px;
                line-height: 36px;
            }
            .el-form-item__content {
                position: relative;
                width: 100% !important;
                .form-item-label {
                    height: 34px;
                    width: 68px;
                    text-align: center;
                    display: inline-block;
                    position: absolute;
                    z-index: 1;
                    top: 1px;
                    left: 1px;
                    white-space: nowrap;
                    background: #fff;
                    line-height: 34px;
                    font-size: 14px;
                    color: #333;
                    border-radius: 4px;
                    &::after {
                        content: "";
                        display: inline-block;
                        width: 1px;
                        height: 18px;
                        background: #ddd;
                        position: absolute;
                        right: 0;
                        top: 8px;
                    }
                    &.form-item-select {
                        .el-input__inner {
                            padding: 0 20px;
                            border: none;
                            height: 34px;
                            line-height: 34px;
                            font-size: 14px;
                            color: #333;
                        }
                    }
                    &.search-select-key {
                        width: 120px;
                        &::after {
                            display: none;
                        }
                    }
                    &.search-select-companyname {
                        width: 106px;
                    }
                    .el-input__icon {
                        width: 20px;
                        line-height: 34px;
                        color: #333;
                    }
                }
            }
            .el-input__inner {
                padding-left: 78px;
                font-size: 14px;
                color: #666;
                &::placeholder {
                    color: #999;
                    font-size: 14px;
                }
            }

            &.location-item {
                
                .el-form-item__content {
                    display: flex;
                    align-items: center;
                    width: 815px;
                    height: 36px;
                    line-height: 36px;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    overflow: hidden;
                }

                .search-input-key {
                    width: 100%;

                    .el-input__inner {
                        padding-left: 0;
                        height: 34px;
                        line-height: 34px;
                        border: 0 none;
                    }
                }
            }
        }
        .form-select-item.el-form-item {
            .search-input.el-date-editor {
                .el-input__icon {
                    display: none;
                }
            }
            .el-input__inner {
                // width: 397px;
                width: 100%;
            }
        }
        .el-age-editor.el-date-editor.el-input__inner:hover {
            border-color: #ddd;

            &.is-active:hover {
                border-color: $primary;
            }
        }
        .search-input-label.el-form-item {
            // width: 397px;
            width: 100%;
            margin-bottom: 10px;
            &.job-hunting-will {
                width: 815px;
            }
            .el-form-item__label {
                color: #333;
                margin: 0;
            }
            .button {
                height: 36px;
                line-height: 36px;
            }
            .el-form-item__content {
                line-height: 36px;
            }
        }
        .customer-select-input,
        .search-input.el-input,
        .search-input.el-autocomplete {
            // width: 397px;
            width: 100%;
            color: #666;
            &.search-input-key {
                // width: 815px;
                width: 100%;
                .el-input__inner {
                    padding: 0;
                    padding-left: 78px;
                }
            }
            &.company-name {
                .el-input__inner {
                    padding: 0;
                    padding-left: 116px;
                }
            }
        }
        .search-input.el-button-group {
            .el-button.el-button--default {
                padding: 0;
                width: 76px;
                text-align: center;
                font-size: 14px;
                background: #fff;
                color: #666;
                border: 1px solid #ddd;
                &:nth-of-type(1) {
                    border-top-left-radius: 14px;
                    border-bottom-left-radius: 14px;
                }
                &:nth-last-of-type(1) {
                    border-top-right-radius: 14px;
                    border-bottom-right-radius: 14px;
                }
                &.selected {
                    background: $primary;
                    color: #fff;
                    border-color: $primary;
                }
            }
        }
        .radio-list.el-radio-group {
            .el-radio {
                margin-right: 20px;
                margin-bottom: 0;
                line-height: 36px;
                .el-radio__label {
                    padding-left: 6px;
                }
            }
            .el-radio.is-checked {
                .el-radio__label {
                    color: #666;
                }
            }
        }
        .search-tip {
            width: 100%;
            padding: 25px 0;
            border-top: 1px solid #ddd;
            display: flex;
            .tip-title {
                width: 90px;
                display: flex;
                flex-direction: column;
                align-items: center;
                color: $primary;
                .icon-font {
                    font-size: 28px;
                    margin-bottom: 6px;
                    color: $primary;
                }
            }
            .tip-content {
                margin-bottom: 0;
                margin-right: 10px;
            }
            .icon-font.icon-quxiao {
                font-size: 20px;
                color: #979797;
                cursor: pointer;
                &:hover {
                    color: red;
                }
            }
        }
    }
}
.personal-el-dropmenu{
    .el-scrollbar{
        ul{
            padding: 0;
            li.el-select-dropdown__item{
                padding: 4px 20px;
                box-sizing: content-box;
                font-size: 15px;
            }
        }
    }
}

.namelist-el-autocomplete{
    // width: 368px !important;
    // left: 17px !important;
    .el-scrollbar{
        .el-scrollbar__wrap{
            max-height: 380px;
            padding: 0;
        }
        ul li{
            border-bottom: 1px solid #e1e1e1;
            padding: 3px 20px;
            position: relative;
            .name{
                color: #b9b9b9;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .selected{
                color: #38bc9d;
                &::after{
                    display: inline-block;
                    float: right;
                    width: 20px;
                    height: 20px;
                    font-family: icon-font!important;
                    font-size: 16px;
                    font-style: normal;
                    -webkit-font-smoothing: antialiased;
                    -webkit-text-stroke-width: .2px;
                    content: "\e626";
                    position: absolute;
                    right: 2px;
                }
            }
        }
    }
}

.filter-list-received{
    float: left;
    padding-top: 5px;
    #filter-input{
        opacity: 0;
        outline: none;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
    }
    .filter-radio{
        line-height: 1;
        display: inline-block;
        cursor: pointer;
        .el-radio__input{
            outline: none;
            display: inline-block;
            line-height: 1;
            vertical-align: middle;
            .el-radio__inner{
                border: 1px solid #dcdfe6;
                border-radius: 100%;
                width: 14px;
                height: 14px;
                background-color: #fff;
                position: relative;
                display: inline-block;
                &:after{
                    width: 4px;
                    height: 4px;
                    border-radius: 100%;
                    background-color: #fff;
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%) scale(0);
                    transition: transform .15s cubic-bezier(.71,-.46,.88,.6);
                }
            }
        }
        .el-radio__label{
            padding-left: 2px;
        }
    }
    .filter-radio.active{
        .el-radio__inner{
            border-color: #38bc9c;
            background: #38bc9c;
            &:after{
                transform: translate(-50%,-50%) scale(1);
            }
        }
        .el-radio__label{
            color: #38bc9c;
        }
    }
}
</style>


