var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "namelist-content-border namelist-candidate-vue",
      staticStyle: { position: "relative" },
    },
    [
      _vm.enableHeader
        ? _c(
            "ul",
            { staticClass: "namelist-candidate-header" },
            _vm._l(_vm.headerItems, function (headerItem) {
              return _c(
                "span",
                { staticClass: "namelist-candidate-header-item" },
                [
                  headerItem.text === "寻访记录"
                    ? _c(
                        "li",
                        [
                          _c("span", {
                            domProps: { textContent: _vm._s(headerItem.text) },
                          }),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                placement: "right",
                                effect: "light",
                                "popper-class": "namelist-right-tooltip",
                              },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v("简易人才库的简历，寻访记录公司"),
                                  _c("br"),
                                  _vm._v("成员共享"),
                                ]
                              ),
                              _c("i", {
                                staticClass: "icon-font icon-question-circle-o",
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  headerItem.text !== "寻访记录"
                    ? _c("li", {
                        domProps: { textContent: _vm._s(headerItem.text) },
                      })
                    : _vm._e(),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _c("div", { staticClass: "namelist-candidate-store" }, [
        _c(
          "ul",
          _vm._l(_vm.candidateList, function (item, index) {
            return _c(
              "li",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: item.loading,
                    expression: "item.loading",
                  },
                ],
                key: item.id,
                staticClass: "candidate-item",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "candidate-row clearfix",
                    class: { "can-view-detail": item.isReceived },
                    on: {
                      click: function ($event) {
                        return _vm.handleViewDetail(item, index)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "candidate-row-item candidate-row-name" },
                      [
                        _c("span", {
                          attrs: { title: item.realName },
                          domProps: { textContent: _vm._s(item.realName) },
                        }),
                        item.hasResume
                          ? _c(
                              "span",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      placement: "top",
                                      effect: "light",
                                      "popper-class": "namelist-top-tooltip",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v("企业人才库中已有这份简历，可"),
                                        _c("br"),
                                        _vm._v("直接点击查看"),
                                      ]
                                    ),
                                    _c("i", {
                                      staticClass:
                                        "list-label-item list-label-company",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        !item.hasResume && item.hasResumeZero
                          ? _c(
                              "span",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      placement: "top",
                                      effect: "light",
                                      content: "有其对应的相关完整简历",
                                      "popper-class": "namelist-top-tooltip",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "list-label-item list-label-resume",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c("div", {
                      staticClass: "candidate-row-item candidate-row-location",
                      class: { "color-light": !item.locationName },
                      attrs: { title: item.locationName },
                      domProps: {
                        textContent: _vm._s(
                          item.locationName ? item.locationName : "ㅡ"
                        ),
                      },
                    }),
                    _c("div", {
                      staticClass: "candidate-row-item candidate-row-company",
                      class: { "color-light": !item.company },
                      attrs: { title: item.company },
                      domProps: {
                        textContent: _vm._s(item.company ? item.company : "ㅡ"),
                      },
                    }),
                    _c("div", {
                      staticClass: "candidate-row-item candidate-row-depart",
                      attrs: { title: item.department },
                      domProps: { textContent: _vm._s(item.department) },
                    }),
                    _c("div", {
                      staticClass: "candidate-row-item candidate-row-title",
                      attrs: { title: item.title },
                      domProps: { textContent: _vm._s(item.title) },
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "candidate-row-item candidate-row-update-time",
                      },
                      [_vm._v(_vm._s(_vm._f("date")(item.sourceUpdated)))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "candidate-row-item candidate-row-phone-email",
                      },
                      [
                        !_vm.isGettingLabel
                          ? _c("span", [
                              item.isReceived
                                ? _c("span", [
                                    item.hasResume
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "phone-email-item view-link",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "btn-text" },
                                              [_vm._v("查看")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !item.hasResume
                                      ? _c(
                                          "div",
                                          { staticClass: "phone-email-wrap" },
                                          [
                                            !item.email
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "phone-wrap contact-item",
                                                  },
                                                  [
                                                    _c("span", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          item.mobile
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                            item.email
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "both-wrap contact-item",
                                                  },
                                                  [
                                                    _c("span", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          item.mobile
                                                        ),
                                                      },
                                                    }),
                                                    _c("br"),
                                                    _c("span", {
                                                      attrs: {
                                                        title: item.email,
                                                      },
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          item.email
                                                        ),
                                                      },
                                                    }),
                                                    _c("br"),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              !item.isReceived
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "phone-email-item receive-btn",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleGetList(item, index)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "btn-text" }, [
                                        _vm._v("领取"),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "candidate-row-item candidate-row-inquiry",
                      },
                      [
                        _c("div", { staticClass: "inquiry-wrap" }, [
                          item.hasResume
                            ? _c("span", { staticClass: "text-empty" }, [
                                _vm._v("ㅡ"),
                              ])
                            : _vm._e(),
                          !item.hasResume
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.inquiryLogCount > 0,
                                        expression: "item.inquiryLogCount > 0",
                                      },
                                    ],
                                    staticClass: "view-inquiry",
                                  },
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top",
                                          width: "364",
                                          trigger: "hover",
                                          "popper-class":
                                            "namelist-inquiry-popper",
                                        },
                                        on: {
                                          show: function ($event) {
                                            return _vm.handleInquiryList(
                                              item.id,
                                              item.resumeId,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "namelist-inquiry-list",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "inquiry-list-header",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "add-inquiry-btn",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleAddInquiry(
                                                          item,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("添加寻访记录")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "loading",
                                                    rawName: "v-loading",
                                                    value: item.inquiryLoading,
                                                    expression:
                                                      "item.inquiryLoading",
                                                  },
                                                ],
                                                staticClass:
                                                  "inquiry-list-content",
                                              },
                                              _vm._l(
                                                _vm.inquiryListArr,
                                                function (inquiryItem) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "inquiry-list-item",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "base-info",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "base-info-item",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  inquiryItem.creatorRealName
                                                                ) +
                                                                  "@" +
                                                                  _vm._s(
                                                                    inquiryItem.creatorNickName
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "base-info-item",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  inquiryItem.created
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          inquiryItem.isCanDelete
                                                            ? _c("span", {
                                                                staticClass:
                                                                  "base-info-item",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.deleteShow(
                                                                        item.resumeId,
                                                                        inquiryItem.sourceId,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "item-content",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                        " +
                                                              _vm._s(
                                                                inquiryItem.content
                                                              ) +
                                                              "\n                                                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "ul",
                                                        {
                                                          staticClass:
                                                            "inquiry-info-list",
                                                        },
                                                        [
                                                          inquiryItem.jobHoppingOccasion
                                                            ? _c(
                                                                "li",
                                                                {
                                                                  staticClass:
                                                                    "inquiry-info-item",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "跳槽时机：" +
                                                                      _vm._s(
                                                                        inquiryItem.jobHoppingOccasion
                                                                      )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          inquiryItem.employerInfo
                                                            ? _c(
                                                                "li",
                                                                {
                                                                  staticClass:
                                                                    "inquiry-info-item",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "雇主画像：" +
                                                                      _vm._s(
                                                                        inquiryItem.employerInfo
                                                                      )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          inquiryItem.expectSalary
                                                            ? _c(
                                                                "li",
                                                                {
                                                                  staticClass:
                                                                    "inquiry-info-item",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "薪资报酬：" +
                                                                      _vm._s(
                                                                        inquiryItem.expectSalary
                                                                      )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                        _c("span", {
                                          staticClass:
                                            "inquiry-count enable-view-inquiry",
                                          attrs: { slot: "reference" },
                                          domProps: {
                                            textContent: _vm._s(
                                              item.inquiryLogCount
                                            ),
                                          },
                                          slot: "reference",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.inquiryLogCount == 0,
                                        expression: "item.inquiryLogCount == 0",
                                      },
                                    ],
                                  },
                                  [
                                    item.canEdit
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "view-inquiry",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.handleAddInquiry(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "inquiry-count",
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    !item.canEdit
                                      ? _c(
                                          "span",
                                          { staticClass: "text-empty" },
                                          [_vm._v("ㅡ")]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _c("namelist-inquiry-dialog", {
        ref: "namelistInquiryDialog",
        staticClass: "inquiryAdd-dialog",
        attrs: {
          "namelist-id": _vm.InquiryNamelistId,
          "resume-id": _vm.InquiryResumeId,
          "item-index": _vm.InquiryItemIndex,
        },
        on: { "namelist-inquiry-add": _vm.handleInquiryAdd },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "namelist-delete-dialog",
          attrs: { visible: _vm.dialogVisible, width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("span", [_vm._v("确定删除此条寻访记录？")]),
          ]),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.namelistDeleteLoading,
                  },
                  on: { click: _vm.handleDelete },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }