<template>
    <div class="namelist-content-border namelist-candidate-vue" style="position: relative">
        <ul v-if="enableHeader" class="namelist-candidate-header">
            <span v-for="headerItem in headerItems" class="namelist-candidate-header-item">
                <li v-if="headerItem.text ==='寻访记录'">
                    <span v-text="headerItem.text"></span>
                    <el-tooltip placement="right" effect="light" popper-class="namelist-right-tooltip">
                        <div slot="content">简易人才库的简历，寻访记录公司<br/>成员共享</div>
                        <i class="icon-font icon-question-circle-o"></i>
                    </el-tooltip>
                </li>
                <li v-if="headerItem.text !=='寻访记录'" v-text="headerItem.text">
                </li>
            </span>
        </ul>
        <div class="namelist-candidate-store">
            <ul>
                <li class="candidate-item" v-for="(item, index) in candidateList" :key="item.id" v-loading="item.loading">
                    <div class="candidate-row clearfix" @click="handleViewDetail(item,index)" :class="{'can-view-detail': item.isReceived}">
                        <div class="candidate-row-item candidate-row-name">
                            <span v-text="item.realName" :title="item.realName"></span>
                            <span v-if="item.hasResume">
                                <el-tooltip placement="top" effect="light" popper-class="namelist-top-tooltip">
                                    <div slot="content">企业人才库中已有这份简历，可<br/>直接点击查看</div>
                                    <i class="list-label-item list-label-company"></i>
                                </el-tooltip>
                            </span>
                            <span v-if="!item.hasResume && item.hasResumeZero">
                                <el-tooltip placement="top" effect="light" content="有其对应的相关完整简历" popper-class="namelist-top-tooltip">
                                    <i class="list-label-item list-label-resume"></i>
                                </el-tooltip>
                            </span>
                        </div>
                        <div class="candidate-row-item candidate-row-location" v-text="item.locationName ? item.locationName : 'ㅡ'" :class="{'color-light': !item.locationName}" :title="item.locationName"></div>
                        <div class="candidate-row-item candidate-row-company" v-text="item.company ? item.company : 'ㅡ'" :class="{'color-light': !item.company}" :title="item.company"></div>
                        <div class="candidate-row-item candidate-row-depart" v-text="item.department" :title="item.department"></div>
                        <div class="candidate-row-item candidate-row-title" v-text="item.title" :title="item.title"></div>
                        <div class="candidate-row-item candidate-row-update-time">{{item.sourceUpdated | date}}</div>
                        <div class="candidate-row-item candidate-row-phone-email">
                            <span v-if="!isGettingLabel">
                                <span v-if="item.isReceived">
                                    <div v-if="item.hasResume" class="phone-email-item view-link">
                                        <span class="btn-text">查看</span>
                                    </div>
                                    <div v-if="!item.hasResume" class="phone-email-wrap">
                                        <div v-if="!item.email" class="phone-wrap contact-item">
                                            <span v-text="item.mobile"></span>
                                        </div>
                                        <div v-if="item.email" class="both-wrap contact-item">
                                            <span v-text="item.mobile"></span><br/>
                                            <span v-text="item.email" :title="item.email"></span><br/>
                                        </div>
                                    </div>
                                </span>
                                <div v-if="!item.isReceived" class="phone-email-item receive-btn" @click.stop="handleGetList(item,index)">
                                    <span class="btn-text">领取</span>
                                </div>
                            </span>
                        </div>
                        <div class="candidate-row-item candidate-row-inquiry">
                            <div class="inquiry-wrap">
                                <span v-if="item.hasResume" class="text-empty">ㅡ</span>
                                <div v-if="!item.hasResume">
                                    <div v-show="item.inquiryLogCount > 0" class="view-inquiry" >
                                        <el-popover placement="top" width="364" trigger="hover" popper-class="namelist-inquiry-popper" @show="handleInquiryList(item.id,item.resumeId,index)">
                                            <div class="namelist-inquiry-list">
                                                <div class="inquiry-list-header">
                                                    <span class="add-inquiry-btn" @click="handleAddInquiry(item,index)">添加寻访记录</span>
                                                </div>
                                                <div class="inquiry-list-content" v-loading="item.inquiryLoading">
                                                    <div class="inquiry-list-item" v-for="inquiryItem in inquiryListArr">
                                                        <div class="base-info">
                                                            <span class="base-info-item" >{{inquiryItem.creatorRealName}}@{{inquiryItem.creatorNickName}}</span>
                                                            <span class="base-info-item">{{inquiryItem.created}}</span>
                                                            <span v-if="inquiryItem.isCanDelete" class="base-info-item" @click="deleteShow(item.resumeId,inquiryItem.sourceId,index)"></span>
                                                        </div>
                                                        <div class="item-content">
                                                            {{inquiryItem.content}}
                                                        </div>
                                                        <ul class="inquiry-info-list">
                                                                <li v-if="inquiryItem.jobHoppingOccasion" class="inquiry-info-item" >跳槽时机：{{inquiryItem.jobHoppingOccasion}}</li>
                                                                <li v-if="inquiryItem.employerInfo" class="inquiry-info-item" >雇主画像：{{inquiryItem.employerInfo}}</li>
                                                                <li v-if="inquiryItem.expectSalary" class="inquiry-info-item" >薪资报酬：{{inquiryItem.expectSalary}}</li>
                                                            </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <span slot="reference" class="inquiry-count enable-view-inquiry" v-text="item.inquiryLogCount"></span>
                                        </el-popover>
                                    </div>
                                    <div v-show="item.inquiryLogCount == 0">
                                        <div v-if="item.canEdit" class="view-inquiry" @click.stop="handleAddInquiry(item,index)">
                                            <span slot="reference" class="inquiry-count"></span>
                                        </div>
                                        <span v-if="!item.canEdit" class="text-empty">ㅡ</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <!-- <namelist-inquiry-list></namelist-inquiry-list> -->
        <namelist-inquiry-dialog class="inquiryAdd-dialog" ref="namelistInquiryDialog" :namelist-id="InquiryNamelistId" :resume-id="InquiryResumeId" :item-index="InquiryItemIndex" @namelist-inquiry-add="handleInquiryAdd"></namelist-inquiry-dialog>
        <el-dialog :visible.sync="dialogVisible" width="500px" class="namelist-delete-dialog">
            <div class="dialog-content">
                <span>确定删除此条寻访记录？</span>
            </div>
            <div class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" :loading="namelistDeleteLoading" @click="handleDelete">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { my, candidate_label_cloud, name_list, candidate as candidateUrl } from '#/js/config/api.json';
import namelistInquiryDialog from './namelist-inquiry-dialog.vue'

const HEADER_ITEM = [
    {text: '姓名'},
    {text: '地区'},
    {text: '公司'},
    {text: '部门'},
    {text: '职位'},
    {text: '更新时间'},
    {text: '手机/邮箱'},
    {text: '寻访记录'}
];

export default {
    components:{
        namelistInquiryDialog,
    },
    props:{
        currentPage: Number,
        pageViewId: String,
        searchId: String,
        candidateList: {
            type: Array,
            default: function() {
                return []
            }
        }
    },
    computed: {
        enableHeader() {
            return this.candidateList.length > 0
        }
    },
    data(){
        return{
            headerItems:HEADER_ITEM,
            isGettingLabel:true,
            isRequiring:false,
            InquiryNamelistId:'',
            InquiryResumeId:'',
            InquiryItemIndex:0,
            take:30,
            startDate:'',
            firstGet:true,
            inquiryListArr:[],
            dialogVisible:false,
            deleteResumeId:'',
            deleteSourceId:'',
            deleteIndex:'',
            items:[],
            namelistDeleteLoading: false
        }
    },
    mounted(){
        this.inquiryDialog = this.$refs.namelistInquiryDialog
    },
    methods: {
        deleteShow(resumeId,sourceId,index){
            this.dialogVisible=true;
            this.deleteResumeId = resumeId;
            this.deleteSourceId = sourceId;
            this.deleteIndex = index;
        },
        handleDelete() {
            if(this.namelistDeleteLoading) {
                return false;
            }
            let resumeId = this.deleteResumeId;
            let sourceId = this.deleteSourceId;
            this.namelistDeleteLoading = true;
            _tracker.track('namelistDeleteInquiry',{
                    inquiryLogId: sourceId,
                    resumeId: resumeId
            });

            _request({
                url: candidateUrl.namelist_delete_inquiry.replace('%p', resumeId).replace('%p', sourceId),
                method: 'DELETE'
            }).then(res => {
                this.getListLabel(this.deleteIndex);
            }).finally(() => {
                this.namelistDeleteLoading = false;
                this.dialogVisible = false;
            })
        },
        handleInquiryList(id,resumeId,index){
            let url='', _id = id, _resumeId =resumeId;
            this.inquiryListArr =[];
            this.firstGet=true;
            this.startDate='';
            this.$emit('open-candidate-inquiry-loading', index);
            if(this.firstGet) {
                url = candidateUrl.namelist_get_inquiry_candidate_first.replace('%p', _id).replace('%p', _resumeId).replace('%p', this.take);
            }else {
                url = candidateUrl.get_inquiry_candidate.replace('%p', _id).replace('%p', _resumeId).replace('%p', this.startDate).replace('%p', this.take);
            }


            _request({
                url: url,
                method: 'GET'
            }).then(res => {
                if(res && res.length > 0) {
                    this.firstGet = false;
                    this.startDate = res[res.length-1].created;
                    this.inquiryListArr = res;
                }
            }).finally(() =>{
                this.$emit('close-candidate-inquiry-loading', index);
            })
        },
        handleInquiryAdd(index){
            this.getListLabel(index);
        },
        getListLabel(index) {
            this.$emit('open-candidate-loading', index);
            let item = this.candidateList[index];
            let param = [{
                id: item.id,
                realName: item.realName,
                mobile: item.mobile,
                email: item.email,
                resumeId: item.resumeId
            }]

            if(!param || param.length == 0) {
                return false;
            }


            _request({
                url: candidateUrl.namelist_get_labels,
                method: 'POST',
                data: {
                    nameListInfos: param
                }
            }).then(res => {
                this.setListLabel(res);
                this.inquiryListArr = [];
            }).finally(() => {
                this.$emit('close-candidate-loading', index);
            })
        },

        getListLabels() {
            let param = this.getCurrentListIds(this.candidateList);

            this.isGettingLabel = true;

            if(!param || param.length == 0) {
                return false;
            }

            _request({
                url: candidateUrl.namelist_get_labels,
                method: 'POST',
                data: {
                    nameListInfos: param
                }
            }).then(res => {
                this.setListLabel(res);
            }).finally(() => {
                this.isGettingLabel = false;
            })
        },

        getCurrentListIds(items) {
            let params = [];

            if(!items || items.length === 0) {
                return params;
            }

            items.forEach((item, index) =>{
                let param = {
                    id: item.id,
                    realName: item.realName,
                    mobile: item.mobile,
                    email: item.email,
                    resumeId: item.resumeId
                }

                params.push(param);
            });

            return params;
        },

        setListLabel(arr) {
            let self = this;
            this.candidateList.forEach((item, index) =>{
                for(let i = 0; i < arr.length; i++) {
                    if(arr[i].nameListId == item.id) {
                        item.canEdit = arr[i].canEdit;
                        item.hasResume = arr[i].hasResume;
                        item.hasResumeZero = arr[i].hasResumeZero;
                        item.candidateId = arr[i].candidateId;
                        item.resumeId = arr[i].resumeId;
                        item.inquiryLogCount = arr[i].inquiryLogCount;
                        if(arr[i].candidateId) {
                            item.isReceived=true;
                        }
                        return ;
                    }
                }
            });
        },

        handleGetList(list,index) {
            if(this.isRequiring) {
                return ;
            }
            this.$emit('open-candidate-loading', index);
            this.isRequiring=true;

            _tracker.track('namelistGetBtnClick',{
                namelistId: list.id,
                hasResumeZero: list.hasResumeZero
            });


            _request({
                url: candidateUrl.namelist_get_list.replace('%p', list.id),
                method: 'GET'
            }).then(res => {
                list.mobile = res.mobile;
                list.email = res.email;
                list.isReceived = true;
                list.canEdit = res.canEdit;
                list.resumeId = res.resumeId;
            }).finally(() => {
                this.$emit('close-candidate-loading', index);
                this.isRequiring = false;
            })
        },

        handleViewDetail(data,index) {
            if(!data.isReceived) {
                return ;
            }

            _tracker.track('namelistViewDetail',{
                    namelistId: data.id,
                    candidateId: data.candidateId,
                    userId:this.$store.state.user.userInfo.id,
            });

            let logData = {
                searchId: this.searchId,
                pageIndex: this.currentPage,
                itemRank: index,
                candidateId: data.candidateId,
                tbdResumeId: data.resumeId,
                namelistId: data.id
            };

            _request({
                url: candidateUrl.namelist_view_detail_log,
                method: 'POST',
                data: logData
            })

            if(data.candidateId) {
                window.open('#/candidateDetail/' + data.candidateId, "_blank");
            }else {
                window.open('#/candidateDetail/' + data.resumeId, "_blank");
                // window.open('/IndividualCandidate/' + data.resumeId, "_blank");
            }
            return true;
        },

        handleAddInquiry(data, index) {
            this.inquiryDialog.showModal();
            this.InquiryResumeId= data.resumeId;
            this.InquiryNamelistId = data.id;
            this.InquiryItemIndex = index;
        },
    },
};

</script>

<style lang="scss" scoped>
.namelist-candidate-header {
    font-size: 0;
    padding: 15px 12px;
    border-bottom: 1px solid #f6f6f6;

    &-item {
        font-size: 14px;
        display: inline-block;
        margin-right: 20px;
        text-align: center;
        color: #4a4a4a;
        text-align: left;

        &:nth-child(1) {
            width: 70px;
            padding-left: 25px;
        }

        &:nth-child(2) {
            width: 50px;
        }

        &:nth-child(3) {
            width: 80px;
        }

        &:nth-child(4) {
            width: 80px;
        }

        &:nth-child(5) {
            width: 95px;
        }

        &:nth-child(6) {
            width: 85px;
        }

        &:nth-child(7) {
            width: 130px;
        }

        &:nth-child(8) {
            width: 80px;
            margin-right: 0;
            text-align: center;

            .icon-font {
                &:hover {
                    color: #38bc9c;
                }
            }
        }
    }
}

.namelist-candidate-store {

    .candidate-item {
        padding: 0 7px;
        border-top: none;
        border-bottom: 1px solid #f6f6f6;
        position: relative;

        .center-info {
            width: 100%;
        }

        &:hover {
            background: #f6f6f6;
        }
    }

    .can-view-detail {
        &:hover {
            cursor: pointer;
        }
    }

    .candidate-row {
        font-size: 0;
        color: #4a4a4a;

        .color-light {
            color: #999;
        }

        &-item {
            display: inline-block;
            font-size: 14px;
            height: 48px;
            line-height: 48px;
            min-width: 30px;
            margin-right: 20px;
            text-align: left;
            vertical-align: top;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:nth-child(1) {
                width: 70px;
                position: relative;
                text-align: left;
                padding-left: 25px;

                .doll-container {
                    float: left;
                    height: 16px;
                }

                .list-label-item {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 16px;

                    .icon-font {
                        color: transparent;
                    }
                }

                .list-label-company {
                    width: 16px;
                    background: url('~@src/assets/images/name-list-company.png') no-repeat;
                }

                .list-label-resume {
                    width: 28px;
                    background: url('~@src/assets/images/name-list-resume.png') no-repeat;

                    .icon-font {
                        width: 28px;
                    }
                }
            }

            &:nth-child(2) {
                width: 50px;
            }

            &:nth-child(3) {
                width: 80px;
            }

            &:nth-child(4) {
                width: 80px;
            }

            &:nth-child(5) {
                width: 95px;
            }

            &:nth-child(6) {
                width: 85px;
            }

            &:nth-child(7) {
                width: 130px;
                padding: 0;
                font-size: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                .phone-email-item {
                    width: 91px;
                    text-align: center;
                    height: 28px;
                    line-height: 28px;
                    border-radius: 2px;
                    height: 100%;
                    font-size: 14px;
                    position: relative;
                }

                .phone-email-wrap {
                    position: relative;

                    .contact-item {
                        color: #4a4a4a;
                        vertical-align: middle;
                        font-size: 14px;

                        span {
                            display: inline-block;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    .both-wrap {
                        height: 48px;
                        line-height: 1.2;
                        padding-top: 8px;
                    }
                }

                .receive-btn {
                    color: #fff;

                    .btn-text {
                        background: #38bc9c;
                        width: 91px;
                        height: 28px;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        top: 0;
                        cursor: pointer;
                        border-radius: 2px;
                    }
                }

                .view-link {
                    color: #38BC9C;

                    .btn-text {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        height: 28px;
                        width: 91px;
                        left: 0;
                        right: 0;
                        cursor: pointer;
                        border: 1px solid #38bc9c;
                        border-radius: 2px;
                    }
                }
            }

            &:nth-child(8) {
                width: 80px;
                margin-right: 0;
                text-align: center;

                .inquiry-wrap {
                    height: 100%;

                    .text-empty {
                        color: #999;
                    }

                    .view-inquiry {
                        position: relative;

                        .inquiry-count {
                            position: relative;
                            cursor: pointer;
                            padding-left: 20px;

                            &::before {
                                content: ' ';
                                position: absolute;
                                width: 19px;
                                height: 19px;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                margin: auto;
                                background: url('~@src/assets/images/nl-icon-edit.png') no-repeat;
                            }
                        }

                        .enable-view-inquiry {

                            &::before {
                                background: url('~@src/assets/images/nl-icon-record.png') no-repeat;
                            }
                        }

                        .inquiry-list-wrap {
                            position: absolute;
                            width: 364px;
                            height: 298px;
                            border-radius: 4px;
                            box-shadow: 0 0 6px 0;
                        }
                    }
                }
            }
        }
    }
}

.candidate-info {
    width: 45%;
    float: left;

    .fa {
        float: left;
        line-height: inherit;
        width: 20px;
        margin-right: 0;
    }

    li {
        p {
            max-width: 85%;
            margin-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .location-info {
        color: #b9b9b9;
    }
}

/*自定义dark-popover*/
.dark-popover{
    background: #4d4d4d;
    color: #fff;
    border-radius: 4px;
    min-width: 60px;
    text-align: center;
    &.bs-tether-element-attached-bottom .popover-arrow:after{
        border-top-color: #4d4d4d;
    }
    .popover-content{
        color: #fff;
    }
}
// 暂时隐藏寻访列
.namelist-candidate-vue{
    .inquiryAdd-dialog .inquiry-modal{
        z-index: 9999;
    }
    .namelist-candidate-header{
        margin-bottom: 0px;
        .icon-question-circle-o{
            padding-left: 3px;
        }
    }
    .pager.pager-jump{
        text-align: left;
    }
    .namelist-delete-dialog{
        top:10%;
        .el-dialog{
            height: 300px;
        }
        .el-dialog__header{
            display: none;
        }
        .el-dialog__body{
            padding: 30px;
            text-align: center;
            .dialog-content{
                margin-top: 70px;
                margin-bottom: 30px;
                text-align: center;
            }
            .dialog-footer{
                margin-top: 20px;
                .el-button{
                    outline: none;
                    width: 80px;
                    min-width: 80px;
                    height: 30px;
                    line-height: 30px;
                    border-radius: 15px;
                    text-align: center;
                    padding: 0;
                    border: 1px solid #ddd;
                    background: #fff;
                    color: #000;
                    &:hover{
                        border-color: #38bc9c;
                    }
                }
                .el-button.el-button--primary{
                    background: #e7f7f3;
                    color: #38bc9c;
                    border-color: #e7f7f3;
                    &:hover{
                        background: #38bc9c;
                        color: #fff;
                    }
                }
            }
        }
    }
}
.el-tooltip__popper.is-light.namelist-right-tooltip {
    border: 1px solid rgba(0,0,0,.2);
    .popper__arrow{
        border-right-color: rgba(0,0,0,.2);
        border-left-color: rgba(0,0,0,.2);
    }   
}
.el-tooltip__popper.is-light.namelist-top-tooltip {
    border: 1px solid rgba(0,0,0,.2);
    .popper__arrow{
        border-top-color: rgba(0,0,0,.2);
        border-bottom-color: rgba(0,0,0,.2);
    }   
}
.namelist-inquiry-popper{
    padding: 20px;
    .namelist-inquiry-list{
        width: 100%;
        height: 298px;
        .inquiry-list-header{
            padding-bottom: 10px;
            .add-inquiry-btn {
                color: #4a4a4a;
                padding-left: 25px;
                position: relative;
                cursor: pointer;
                &::before {
                    content: ' ';
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 18px;
                    height: 18px;
                    background: url('~@src/assets/images/nl-icon-edit.png') no-repeat;
                    background-size: auto;
                }
            }
        }
        .inquiry-list-content {
            max-height: 268px;
            overflow-y: auto;
            width: 337px;

            .inquiry-list-item {
                border-bottom: 1px solid #eee;
                line-height: 1;
                width: 318px;

                &:nth-child(1) {
                    border-top: 1px solid #eee;
                }

                &:hover {
                    .base-info {
                        &-item {
                            &:nth-child(3) {
                                visibility: visible;
                            }
                        }
                    }
                }

                .base-info {
                    margin-top: 10px;

                    &-item {
                        display: inline-block;
                        margin-right: 10px;
                        height: 18px;
                        vertical-align: top;
                        line-height: 18px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        &:nth-child(1) {
                            width: 153px;
                            color: #666;
                        }

                        &:nth-child(2) {
                            width: 113px;
                            color: #999;
                            font-size: 12px;
                        }

                        &:nth-child(3) {
                            visibility: hidden;
                            width: 22px;
                            height: 18px;
                            cursor: pointer;
                            background: url('~@src/assets/images/nl-delete-default.png') no-repeat;
                            background-size: auto;
                            margin-right: 0;

                            &:hover {
                                background: url('~@src/assets/images/nl-delete-hover.png') no-repeat;
                            }
                        }
                    }
                }

                .item-content {
                    width: 100%;
                    color: #4a4a4a;
                    margin: 10px 0;
                    line-height: 1.5;
                    letter-spacing: 1px;
                    word-break: break-all;
                    word-wrap: break-word;
                }
            }
        }
        .inquiry-info-list{
            .inquiry-info-item{
                position: relative;
                padding: 4px 0 4px 10px;
                font-size: 12px;
                &:before{
                    position: absolute;
                    display: block;
                    content: '';
                    width: 6px;
                    height: 6px;
                    background-color: #000;
                    border-radius: 50%;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}
</style>
